import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Gil from "../assets/images/gil-perez.jpg"

const Resume = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
    <h1 className="CyGroteskGrandBold text-3xl my-5 lg:my-5">Resume</h1>
      <div className="px-5 lg:px-0 md:w-9/12 mx-auto pb-10">
        <div className="grid grid-cols-1 gap-4 text-sm">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
            <a href="https://www.linkedin.com/in/gildardoperez/" target="_blank" rel="nofollow"><img src={Gil} className="w-20" /></a>
            <div className="lg:span-col-11">
              <h2 className="text-lg font-bold">Gil Perez</h2>
              <ul>
                <li>perezgildardo@gmail.com</li>
                <li><a href="https://www.linkedin.com/in/gildardoperez/" target="_blank" rel="nofollow">https://www.linkedin.com/in/gildardoperez/</a></li>
              </ul>
            </div>
          </div>
          <div className="">
              <p>Director of Engineering and Co-Founder at BRADIENT, driving digital transformation and fostering growth. Skilled in building and scaling engineering teams, resolving challenges, and integrating new technologies. Strong foundation in software engineering from roles at Warner Bros, WarnerMedia, and AT&T. Currently leading at 3BLACKDOT, delivering technology-driven solutions aligned with business objectives. Committed to innovation and effective project management.</p>
          </div>
          <br />
          <h2 className="text-lg font-semibold">Experience</h2>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>3BLACKDOT</strong> <i>(October 2020 - Present)</i></li>
              <li>Director Of Engineering</li>
              <li><a href="https://www.3blackdot.com/" target="_blank" rel="nofollow">https://www.3blackdot.com/</a></li>
            </ul>
            <p className="my-2">3BLACKDOT is a digital entertainment company specializing in content creation, talent management, and brand development within the gaming and esports industry. As the Director of Engineering, I'm responsible for the overall YouTube MCN and eCommerce teams, partnering with each talent for their overall monetization, sales, and talent audience development.</p>
            <ul className="list-disc list-inside px-5 py-2">
              <li>Led and managed a team of engineers to provide technical support, development, and maintenance for over 50+ Shopify Plus stores.</li>
              <li>Collaborated closely with clients, including internet personalities and high-profile YouTube streamers, to understand their unique requirements and deliver tailored solutions.</li>
              <li>Developed and implemented strategic plans to ensure optimal performance, scalability, and user experience for the Shopify Plus stores.</li>
              <li>Oversaw the end-to-end development process, including requirements gathering, coding, testing, and deployment.</li>
              <li>Provided technical guidance and mentorship to the team, fostering a collaborative and high-performing work environment.</li>
              <li>Conducted regular code reviews, quality assurance, and performance optimization to maintain high standards and ensure seamless operation of the Shopify Plus stores.</li>
              <li>Acted as the main point of contact for clients, managing relationships, addressing concerns, and ensuring client satisfaction.</li>
            </ul>
          </div>
          <p><strong>Responsibilities:</strong> YouTube Multi-Channel Network (MCN) · Engineering Leadership · Team Director · eCommerce Management · Architecture · Engineering Management · Partner management · Digital Rights Management · Monetization and Sales · Talent Audience Development · Talent Data Aggregation.</p>
          <p><strong>Skills:</strong> Shopify Plus · Cloud Computing · Cloud Security · Amazon AWS · React.js · Gatsby.js · Node.js · JavaScript · Shopify Hydrogen · Headless eCommerce · Headless CMS · Tailwind CSS · OpenAI · ElevenLabs</p>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>BRADIENT LLC</strong></li>
              <li>Co-Founder</li>
              <li><a href="https://www.bradient.com/" target="_blank">https://www.bradient.com/</a></li>
            </ul>
            <p className="my-2">As a Co-Founder and member of the Executive Team, I am responsible for overseeing the creative design team, development team, and the overall maintenance of all clients eCommerce applications and marketing strategies.</p>
            <ul className="list-disc list-inside px-5 py-2">
              <li>Developed and maintained web applications, ensuring optimal performance and responsive user interfaces using technologies like Gatsby.js, React.js, JavaScript, Headless eCommerce, Headless CMS and Tailwind CSS </li>
              <li>Collaborated with cross-functional teams to implement new features and enhance existing functionalities.</li>
              <li>Integrated third-party APIs and services to extend application capabilities and improve user experience.</li>
              <li>Utilized Shopify Plus to create customized e-commerce solutions for clients, including theme customization, and product catalog management.</li>
              <li>Implemented digital marketing strategies, including Facebook Business Manager and Google Ads, to drive traffic, increase conversions, and enhance brand visibility.</li>
              <li>Worked with Amazon Web Services to deploy cloud-based infrastructure and ensure scalability, reliability, and security.</li>
              <li>Implemented and configured Shopify's headless commerce framework Hydrogen to extend the functionality of client's online stores.</li>
            </ul>
          </div>
          <p><strong>Responsibilities:</strong>  Executive Team · Engineering Leadership · eCommerce Director · Engineering Management · Klaviyo marketing · Facebook Business Manager · Google Ads · Technical SEO Specialist · CyberSecurity.</p>
          <p><strong>Skills:</strong> Shopify Plus · Shopify Hydrogen · Amazon AWS · Gatsby.js · React.js · JavaScript · Headless eCommerce · Headless CMS · Tailwind CSS · OpenAI · ElevenLabs · UI/UX Prototyping Design · Project Management</p>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>Warner Bros. Entertainment Group of Companies</strong> <i>(April 2018 - April 2019)</i></li>
              <li>Full Stack Developer</li>
              <li><a href="https://www.warnerbros.com/" target="_blank" rel="nofollow">https://www.warnerbros.com/</a></li>
              <li><a href="https://www.ellentube.com/" target="_blank" rel="nofollow">https://www.ellentube.com/</a></li>
            </ul>
            <p className="my-2">EllenTube, created by Ellen DeGeneres and her production company, Ellen Digital Ventures, is a popular digital platform under Warner Bros. Pictures. As a Full Stack Developer, I played a key role in developing and maintaining both EllenTube web applications, and both iOS and Android platforms and EllenShop utilizing React.js, React Native and Shopify.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Developed and maintained web applications using React.js and React Native ensuring efficient and responsive user interfaces.</li>
                <li>Collaborated with a cross-functional team to implement new features and improve existing functionalities.</li>
                <li>Integrated third-party APIs and services to enhance application capabilities.</li>
                <li>Utilized React Native to develop mobile applications for both iOS and Android platforms.</li>
                <li>Implemented SEO strategies and conducted digital marketing campaigns to drive organic traffic and improve search engine rankings.</li>
                <li>Worked on Shopify Plus to create customized eCommerce solutions for clients, including theme customization, payment gateway integration, and product catalog management.</li>
                <li>Design Ad-supported video on demand integrations</li>
              </ul>
          </div>
            <p className="my-2"><strong>Skills:</strong> React.js · React Native · Shopify · AWS · Adobe Experience Manager (AEM) · Redux · JavaScript · API Integration · UI/UX Prototyping · Ad-supported video on demand</p>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>Quantasy + Associates</strong> <i>(May 2017 - April 2018)</i></li>
              <li>Full Stack Developer</li>
              <li><a href="https://www.quantasy.com/" target="_blank" rel="nofollow">https://www.quantasy.com/</a></li>
              <li><a href="https://laughoutloud.com" target="_blank" rel="nofollow">https://laughoutloud.com</a></li>
            </ul>
            <p className="my-2">Quantasy pioneers immersive brand experiences at the intersection of advertising, entertainment, technology, and culture. As a Full Stack Engineer, I was part of The Engineering team, where I contributed to both React.js and React Native apps for Kevin Hart's Laugh Out Loud and collaborated on the development of Steve Harvey's - Harvey's Hundreds App. Working with cross-functional teams, I aligned solutions with business goals, while implementing best practices for enhanced efficiency and high-quality standards.</p>
              <ul className="list-disc list-inside px-5 py-2">
                  <li>Developed and maintained applications using ReactJS, React Native, and Shopify Plus.</li>
                  <li>Created comprehensive solutions for businesses, improving user experience and customer engagement.</li>
                  <li>Led the full-stack development of the Laugh Out Loud Website (<a href="https://laughoutloud.com" target="_blank" className="underline" rel="nofollow">https://laughoutloud.com</a>), optimizing performance and user experience.</li>
                  <li>Contributed to the development and maintenance of Kevin Hart's Laugh Out Loud App using ReactJS and React Native expertise.</li>
                  <li>Collaborated in a team to build and maintain Steve <a href="http://www.harveyshundreds.com/" target="_blank" className="underline" rel="nofollow">Harvey's - Harvey's Hundreds</a> App, focusing on backend optimization.</li>
                  <li>Worked with cross-functional teams, including digital marketing specialists, to align solutions with business goals.</li>
              </ul>
          <p className="my-2"><strong>Skills:</strong> React.js · React Native · AWS ·  JavaScript · API Integration · Shopify Plus</p>
          </div>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>Whalerock Industries</strong> <i>(April 2015 - May 2017)</i></li>
              <li>Full Stack Developer</li>
              <li><a href="https://www.whalerockindustries.com/" target="_blank" rel="nofollow">https://www.whalerockindustries.com/</a></li>
            </ul>
            <p className="my-2">Whalerock Industries is a pioneering media and technology company known for its digital-first, multi-platform content and technology products. Their projects consist of creating digital media hubs for high-profile celebrities like the Kardashians and Howard Stern. They have also produced popular TV shows like Hyperdrive and Battlebots.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Developed features and prototypes for web and mobile applications for high-profile clients including Kim Kardashian, Kylie Jenner, Kendall Jenner, Khloe Kardashian, and Kourtney Kardashian. Delivered content-rich experiences including makeup tutorials, live streams, video diaries, and workout routines.</li>
                <li>Played a key role in the launch of <a href="https://kyliecosmetics.com/" target="_blank" className="underline" rel="nofollow">Kylie Cosmetics</a>, utilizing Shopify Plus platform to ensure a seamless and efficient online shopping experience.</li>
                <li>Contributed to the development and enhancement of several other projects including Howard Stern, Tested.com, WonderWall.com, Mom.me, and Moviefone.com.</li>
                <li>Leveraged a variety of technologies such as NodeJS, ReactJS, React Native, EmberJS, and AWS to build and maintain scalable applications.</li>
                <li>Employed UI/UX design skills to enhance user interaction and overall user experience across multiple platforms.</li>
              </ul>
            <p className="my-2"><strong>Skills:</strong> React.js · React Native · Python · Django · Shopify · AWS · API Integration · LiveStreaming · Over-the-top (OTT) · Video Services</p>
          </div>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>AOL</strong> <i>(September 2015 - December 2015)</i></li>
              <li>UI Engineer</li>
              <li><a href="https://www.moviefone.com/" target="_blank" rel="nofollow">https://www.moviefone.com/</a></li>
            </ul>
            <p className="my-2">AOL is a digital media company known for its significant online properties, including Moviefone, a comprehensive service for movie schedules, news, reviews, and ticket sales. Both AOL and Moviefone are dedicated to enhancing user experiences and delivering diverse multimedia content.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Integral member of the Engineering team, collaborated closely with the research and technology team based in New York for the successful relaunch of Moviefone.com.</li>
                <li>Developed new features and performance prototypes, improving overall user interaction and interface experience.</li>
                <li>Optimized page speed and implemented caching strategies to enhance site performance and load times.</li>
                <li>Spearheaded search engine optimization efforts to improve website visibility and reach, driving organic traffic growth.</li>
                <li>Improved specific front-end features for users, enhancing usability and customer satisfaction.</li>
                <li>Performed updates and modifications to the existing AOL Content Management System (CMS) platform, ensuring optimal functionality and seamless content management.</li>
              </ul>
          </div>
          <hr className="h-1 border-gray-400" />
          <div className="">
            <ul>
              <li><strong>.XYZ Domain Names</strong> <i>(June 2012 - April 2015)</i></li>
              <li>Front-end Developer</li>
              <li><a href="https://gen.xyz/" target="_blank" rel="nofollow">https://gen.xyz/</a></li>
            </ul>
            <p className="my-2">XYZ.COM LLC, operator of the ".xyz" top-level domain, is a renowned domain name registry providing comprehensive and versatile domain solutions. Established in 2012 and available since 2014, the ".xyz" TLD offers unrestricted use, catering to individuals, businesses, and organizations seeking a unique and adaptable online identity. As a Frontend Developer at XYZ.COM LLC, I contributed to the development of multiple top-level domain projects, enhancing their user interface, performance, and SEO positioning.</p>
              <ul className="list-disc list-inside px-5 py-2">
                <li>Integral part of the team developing multiple top-level domain projects, contributing to both frontend and backend development.</li>
                <li>Collaborated with cross-functional teams to ensure seamless integration and high performance of all elements of the domains.</li>
                <li>Leveraged technical SEO knowledge to enhance website visibility, driving organic traffic and increasing user engagement.</li>
                <li>Employed UI/UX design skills to prototype designs, improving user interaction and overall user experience across multiple platforms.</li>
                <li>Key projects include:</li>
                  <ul className="list-square list-inside px-5 py-2">
                      <li><a href="https://xyz.xyz" target="_blank" rel="nofollow">xyz.xyz</a></li>
                      <li><a href="https://go.college" target="_blank" rel="nofollow">go.college</a></li>
                      <li><a href="https://go.cars" target="_blank" rel="nofollow">go.cars</a></li>
                      <li><a href="http://www.cashadvance.com" target="_blank" rel="nofollow">www.cashadvance.com</a></li>
                  </ul>
              </ul>
          </div>
          <br />
          <h2 className="text-lg font-semibold">Skills</h2>
          <hr className="h-1 border-gray-400" />
          <div className="">
          <ul className="flex flex-wrap">
            <li>Director of Engineering,&nbsp;</li>
            <li>eCommerce Expert,&nbsp;</li>
            <li>Team Director,&nbsp;</li>
            <li>eCommerce Management,&nbsp;</li>
            <li>Technical SEO,&nbsp;</li>
            <li>Amazon Web Services Certified,&nbsp;</li>
            <li>Cybersecurity Certified,&nbsp;</li>
            <li>Cloud Security,&nbsp;</li>
            <li>Amazon AWS,&nbsp;</li>
            <li>Cloud Computing Certified,&nbsp;</li>
            <li>Project Management,&nbsp;</li>
            <li>Team Leadership,&nbsp;</li>
            <li>Client Relationship Management,&nbsp;</li>
            <li>Performance Optimization,&nbsp;</li>
            <li>Problem-Solving,&nbsp;</li>
            <li>Cross-Functional Collaboration,&nbsp;</li>
            <li>Shopify Plus Partners,&nbsp;</li>
            <li>Shopify Hydrogen,&nbsp;</li>
            <li>JavaScript,&nbsp;</li>
            <li>React.js,&nbsp;</li>
            <li>Gatsby.js,&nbsp;</li>
            <li>Node.js,&nbsp;</li>
            <li>React Native,&nbsp;</li>
            <li>Ruby on Rails,&nbsp;</li>
            <li>TailwindCSS,&nbsp;</li>
            <li>CSS3,&nbsp;</li>
            <li>YouTube Multi-Channel Network (MCN),&nbsp;</li>
            <li>Engineering Leadership,&nbsp;</li>
            <li>Engineering Management,&nbsp;</li>
            <li>Partner Management,&nbsp;</li>
            <li>Digital Rights Management,&nbsp;</li>
            <li>Monetization and Sales,&nbsp;</li>
            <li>Talent Audience Development,&nbsp;</li>
            <li>Talent Data Aggregation,&nbsp;</li>
            <li>Headless Commerce Solutions Architect,&nbsp;</li>
            <li>Headless CMS,&nbsp;</li>
            <li>OpenAI,&nbsp;</li>
            <li>Whisper,&nbsp;</li>
            <li>Large Language Model (LLM),&nbsp;</li>
            <li>ElevenLabs,&nbsp;</li>
            <li>Generative AI Text to Speech,&nbsp;</li>
            <li>Voice Cloning,&nbsp;</li>
            <li>Klaviyo Ecommerce Marketing,&nbsp;</li>
            <li>Meta Business Partner,&nbsp;</li>
            <li>Graphic Design,&nbsp;</li>
            <li>Motion Graphics,&nbsp;</li>
            <li>UI/UX Prototyping Design,&nbsp;</li>
            <li>API Integration,&nbsp;</li>
            <li>Agile/Scrum Methodologies,&nbsp;</li>
          </ul>

          </div>
      </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Resume" />

export default Resume

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
